import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Diese Seite wurde nicht gefunden" />
    <h1>Diese Seite existiert nicht</h1>
    <p>Sollten Sie die Downloads meiner Arbeit zu Pilzen im Christentum suchen, finden Sie diese <Link to="/">hier</Link></p>
  </Layout>
)

export default NotFoundPage
